import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import './Battletech.css';
import mechsData from './mechs.json';

function Battletech() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedMech, setSelectedMech] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [gunneryLevel, setGunneryLevel] = useState('');
    const [pilotingLevel, setPilotingLevel] = useState('');
    const [scores, setScores] = useState([]);
    const [mechs, setMechs] = useState([]);
    const [selectedScoreIndex, setSelectedScoreIndex] = useState({ row: null, col: null });
    const [flexTable, setFlexTable] = useState(false);
    const [tableData, setTableData] = useState([]); // Estado para los datos de la tabla
    const [isImportFormVisible, setIsImportFormVisible] = useState(false); // Estado para mostrar el formulario de importación
    const [isNewMechFormVisible, setIsNewMechFormVisible] = useState(false); // Estado para mostrar el formulario de nuevo Mech

    // Referencia al elemento de la tabla
    const tableRef = useRef(null);

    useEffect(() => {
        console.log('Cargando Mechs desde JSON:', mechsData.mechs);
        setMechs(mechsData.mechs);
    }, []);

    const handleSearchChange = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);
    };

    const handleMechModelSelect = (mechName, modelName) => {
        setSelectedMech(mechName);
        setSelectedModel(modelName);

        const mech = mechs.find(mech => mech.name === mechName);
        const model = mech.models.find(model => model.model === modelName);
        setScores(model ? model.scores : []);
        setSelectedScoreIndex({ row: null, col: null });
        setFlexTable(true);
    };

    const handleScoreSelect = (rowIndex, colIndex) => {
        setSelectedScoreIndex({ row: rowIndex, col: colIndex });
        setGunneryLevel(colIndex);  // Guardar el nivel de Gunnery seleccionado
        setPilotingLevel(rowIndex); // Guardar el nivel de Piloting seleccionado
        if (selectedMech && gunneryLevel !== '' && pilotingLevel !== '') {
            const puntaje = scores[pilotingLevel][gunneryLevel]; // Obtener puntaje de la tabla de scores

            const newRow = {
                mech: selectedMech,
                variante: selectedModel,
                gunnery: gunneryLevel,
                piloting: pilotingLevel,
                puntaje: puntaje
            };
            // quitar el mech si ya estaba en la tabla
            const mechIndex = tableData.findIndex(row => row.mech === selectedMech && row.variante === selectedModel);
            if (mechIndex !== -1) {
                const newTableData = [...tableData];
                newTableData.splice(mechIndex, 1);
                setTableData(newTableData);
            }
            // añadir el mech a la tabla
            setTableData((prevData) => [...prevData, newRow]);
        }
    };

    const filteredMechs = mechs.filter(mech =>
        mech.name.toLowerCase().includes(searchTerm) ||
        mech.models.some(model => model.model.toLowerCase().includes(searchTerm))
    );

    // Función para leer el archivo JSON importado
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    setMechs([]);
                    const importedData = JSON.parse(e.target.result);
                    for (const mech of importedData.mechs) {
                        setMechs((prevMechs) => [...prevMechs, mech]);
                    }
                } catch (error) {
                    console.error('Error al leer el archivo JSON:', error);
                    alert('Archivo JSON inválido.');
                }
            };
            reader.readAsText(file);
        }
    };

    // Función para exportar los Mechs como un archivo JSON
    const handleExportMechs = () => {
        const dataStr = JSON.stringify({ mechs }, null, 2); // Convertir los datos a string JSON
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'mechs.json'; // Nombre del archivo de descarga
        a.click(); // Disparar la descarga
        URL.revokeObjectURL(url); // Limpiar la URL
    };

    const handleAddToTable = () => {
        setIsNewMechFormVisible(true);
    };

    const handleRemoveMech = (index) => {
        setTableData((prevTableData) => {
            const newTableData = [...prevTableData];
            newTableData.splice(index, 1);
            return newTableData;
        });
    };

    // Función para exportar la tabla a PNG
    const handleExportToPNG = () => {
        if (tableRef.current) {
            // Clonar la tabla original
            const tableClone = tableRef.current.cloneNode(true);
    
            // Ajustar colSpan en la celda de "Total Puntaje"
            const totalCell = tableClone.querySelector('.total-label');
            const totalValueCell = tableClone.querySelector('.total-value');
            if (totalCell) {
                totalCell.setAttribute('colspan', '4'); // Ajustar el colSpan para reflejar la eliminación de la columna
            }
            if (totalValueCell) {
                totalValueCell.setAttribute('colspan', '5'); // Ajustar el colSpan para reflejar la eliminación de la columna
            }
            // Eliminar la columna de "Acciones" del clon, evitando eliminar total-value y total-label
            const actionCells = tableClone.querySelectorAll('.action-cell');
            actionCells.forEach(cell => cell.remove());
            const actionHeader = tableClone.querySelector('.action-header');
            if (actionHeader) {
                actionHeader.remove();
            }
    
            // Crear un contenedor temporal para renderizar el clon
            const tempContainer = document.createElement('div');
            tempContainer.style.position = 'fixed';
            tempContainer.style.top = '-9999px';
            tempContainer.appendChild(tableClone);
            document.body.appendChild(tempContainer);
    
            // Usar html2canvas en el clon
            html2canvas(tableClone).then((canvas) => {
                // Descargar la imagen generada
                const link = document.createElement('a');
                link.download = 'mechs.png';
                link.href = canvas.toDataURL();
                link.click();
    
                // Limpiar el contenedor temporal
                document.body.removeChild(tempContainer);
            });
        }
    };
    

    return (
        <div id="battletech-calculator">
            <h1 className="title">Battletech Score Calculator</h1>
            
            {/* Tabla donde se añaden los Mechs, agregando una suma de puntajes y un botón para eliminar */}
            <div className="added-mechs-table">
                <h2>Mechs Añadidos</h2>
                {tableData.length === 0 ? (
                    <p>No se han añadido mechs.</p>
                ) : (
                    <>
                        <table className="mech-info-table" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Mech</th>
                                    <th>Variante</th>
                                    <th>Gunnery</th>
                                    <th>Piloting</th>
                                    <th>Puntaje</th>
                                    <th className="action-header">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.mech}</td>
                                        <td>{row.variante}</td>
                                        <td>{row.gunnery}</td>
                                        <td>{row.piloting}</td>
                                        <td>{row.puntaje}</td>
                                        <td className="action-cell">
                                            <button onClick={() => handleRemoveMech(index)}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                                {/* Fila para el total de puntajes */}
                                <tr>
                                    <td colSpan="5" className="total-label" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                        Total Puntaje:
                                    </td>
                                    <td className="total-value" style={{ fontWeight: 'bold' }}>
                                        {tableData.reduce((total, row) => total + row.puntaje, 0)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button onClick={handleExportToPNG}>Exportar a PNG</button>
                    </>
                )}
            </div>

            {/* Botón para agregar a la tabla */}
            <button onClick={handleAddToTable} className="add-to-table-button">
                Añadir a la tabla
            </button>

            {isNewMechFormVisible && (
                <>
                    {/* Barra de búsqueda */}
                    <div className="search-bar-container">
                        <label htmlFor="mech-search">Buscar Mech o Modelo: </label>
                        <input
                            type="text"
                            id="mech-search"
                            className="search-bar"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Buscar Mech o Modelo..."
                        />
                    </div>

                    {/* Botón para mostrar el formulario de importación */}
                    <button onClick={() => setIsImportFormVisible(!isImportFormVisible)} id="import-mech-button">
                        {isImportFormVisible ? 'Cancelar' : 'Importar Mech JSON'}
                    </button>

                    {/* Formulario para importar archivo JSON */}
                    {isImportFormVisible && (
                        <div className="import-form">
                            <h2>Importar Archivo JSON</h2>
                            <input
                                type="file"
                                accept=".json"
                                onChange={handleFileUpload}
                            />
                        </div>
                    )}

                    {/* Botón para exportar los Mechs a un archivo JSON */}
                    <button onClick={handleExportMechs} id="export-mech-button">
                        Exportar Mechs a JSON
                    </button>

                    {/* Verificación para ver si hay mechs cargados */}
                    <div className="mech-table">
                        {mechs.length === 0 ? (
                            <p>No se encontraron mechs</p>
                        ) : (
                            <table className="mech-model-table">
                                <thead>
                                    <tr>
                                        <th className="table-header">Nombre del Mech</th>
                                        <th className="table-header">Modelo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredMechs.map((mech, mechIndex) =>
                                        mech.models.map((model, modelIndex) => (
                                            <tr
                                                key={`${mechIndex}-${modelIndex}`}
                                                className={`mech-row ${selectedMech === mech.name && selectedModel === model.model ? 'selected-row' : ''}`}
                                                onClick={() => handleMechModelSelect(mech.name, model.model)}
                                            >
                                                <td className="mech-name">{mech.name}</td>
                                                <td className="mech-model">{model.model}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>

                    <div className={`table-wrapper ${flexTable ? 'flex-active' : ''}`}>
                        <div className="title-piloting">
                            <p>Piloting</p>
                        </div>
                        <div className="table-container">
                            <div className="title-gunnery">
                                <p>Gunnery</p>
                            </div>
                            <table className="score-table">
                                <thead>
                                    <tr>
                                        <th className="empty-header"></th>
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((level) => (
                                            <th key={level} className="gunnery-header">{level}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {scores.map((row, rowIndex) => (
                                        <tr key={rowIndex} className="score-row">
                                            <th className="piloting-level">{rowIndex}</th>
                                            {row.map((score, colIndex) => (
                                                <td
                                                    key={colIndex}
                                                    className={`score-cell ${selectedScoreIndex.row === rowIndex && selectedScoreIndex.col === colIndex ? 'selected-score' : ''}`}
                                                    onClick={() => handleScoreSelect(rowIndex, colIndex)}
                                                >
                                                    {score}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {selectedScoreIndex.row !== null && selectedScoreIndex.col !== null && (
                        <div className="selected-score-display">
                            <h3>Score Seleccionado: {scores[selectedScoreIndex.row][selectedScoreIndex.col]}</h3>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default Battletech;
